import { Chart, Doughnut, Pie  } from 'react-chartjs-2';
import 'chart.js/auto'; // ADD THIS

import StatCard from "../../components/Cards/StatCard";
import StatCardRound from "../../components/Cards/StatCardRound";
import FilterCard from '../../components/Cards/FilterCard';
import { useEffect, useMemo, useState } from 'react';


let Home = (props) => {
    const env = props.Env
    const [budget, setBudget] = useState(null)
    const AllFiscal = props.AllFiscal
    const [thisFiscalYear, setThisFiscalYear] = useState(AllFiscal[0]?.fiscal)
    const users = props.Users
    const [loaded,setLoaded] = useState(false)
    const [adminmenu, setAdminMenu] = useState('Stats')

    const [statsRequest, isStatsRequest] = useState({
        approved:0,
        pending:0,
        rejected:0,
        total:0, 
        spent:0,
    })

    const [spentMonthly, isSpentMonthly] = useState({
        sep:0,oct:0,nov:0,dec:0,jan:0,feb:0,mar:0,apr:0,may:0,jun:0,jul:0,aug:0
    })

    const [perDepartment, isBudgetPerDepartment] = useState({
        label: [],
        data: []
    })

    const databudgetPerMonth = []

    for(let x in spentMonthly) databudgetPerMonth.push(spentMonthly[x])

    console.log('month data ===> ' , spentMonthly)
    console.log('per department ==> ', perDepartment)
    
    const budgetPerMonth = {
        labels: ['Oct','Nov','Dec','Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul','Aug','Sep'],
        datasets: [{
            label: 'Amount Approved Per Month',
            data: databudgetPerMonth,
    
        }]
    }
    
    const budgetPerDepartment = {
        labels: perDepartment.label,
        datasets: [{
            label: 'Amount Approved',
            data: perDepartment.data,
            backgroundColor: ['#42A5F5', '#EF5350','#00ACC1', '#AB47BC','#FFEE58','#8D6E63','#00BCD4','#8BC34A','#E91E63'],
        }]
    }

    console.log('stats request ===> ', statsRequest)

    let formatMoney = (p) => {
        if(p > 9999 && p < 99999){
            let str = '' + p + ''
            return str.substring(0,2) + 'k'
        }
        else if(p > 99999 && p < 999999){
            let str = '' + p + ''
            return str.substring(0,3) + 'k'
        }
        else if(p > 999999 && p < 9999999){
            let str = '' + p + ''
            return str.substring(0,1) + '.' + str.substring(1,2) + 'm'
        }
        else{
            let str = '' + p + ''
            return str
        }
    }

    let getAllStats = async () => {
        // console.log('check for new requests...');
    
        const requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
    
        try {
            console.time('fetchTime')
            const response = await fetch(`${env}/api/capex/getAllStats?fiscalYear=${thisFiscalYear}`, requestOptions);
            const result = await response.json();
            // console.log('all requests ==> ', result);
            console.timeEnd('fetchTime')

            let approved = 0;
            let pending = 0;
            let rejected = 0;
            let spent = 0;
            let months = {};
            let label = [];
            let dataLabel = [];


            console.time('dataProcessing');
            result.forEach(res => {
                if (res.Status === 'pending' || !res.Status) {
                    pending += 1;
                } else if (res.Status === 'approved') {
                    approved += 1;
                    spent += parseInt(res.EstimatedValue);
                    
                    months[res.ApprovalDateMonth] = (months[res.ApprovalDateMonth] || 0) + parseInt(res.EstimatedValue);
    
                    if (!label.includes(res.Department)) {
                        label.push(res.Department);
                        dataLabel.push(parseInt(res.EstimatedValue));
                    } else {
                        const index = label.indexOf(res.Department);
                        dataLabel[index] += parseInt(res.EstimatedValue);
                    }
                } else {
                    rejected += 1;
                }
            });
            console.timeEnd('dataProcessing')

            isStatsRequest({
                total: approved + pending + rejected,
                approved: approved,
                pending: pending,
                rejected: rejected,
                spent: spent,
            });
    
            isBudgetPerDepartment({
                label: label,
                data: dataLabel
            });
    
            isSpentMonthly({
                oct: months['oct'] || 0,
                nov: months['nov'] || 0,
                dec: months['dec'] || 0,
                jan: months['jan'] || 0,
                feb: months['feb'] || 0,
                mar: months['mar'] || 0,
                apr: months['apr'] || 0,
                may: months['may'] || 0,
                jun: months['jun'] || 0,
                jul: months['jul'] || 0,
                aug: months['aug'] || 0,
                sep: months['sep'] || 0
            });
    
            setLoaded(true)
    
            // setTimeout(getAllStats, 5000);
        } catch (error) {
            console.log('error', error);
        }
    };
    

    let renderUsers = users?.map((data) => {
        let status = () => {
            if(!data.Permission) return 'Unassigned'
            else if(data.Permission == 'admin') return 'Admin'
            else if(data.Permission == 'basic') return 'Basic'
            else return 'Approver Lvl ' + data.Permission[data.Permission.length - 1]
        }
        return(
            <div className="card-inside">
                <div className="row">
                    <div className="col-3 text-center">{data.Name}</div>
                    <div className="col-3 text-center">{data.ID}</div>
                    <div className="col-3 text-center">{data.Joined.substring(0,10)}</div>
                    <div className="col-3 text-center">{status()}</div>
                </div>
            </div>
        )
        
    })

    let renderAllFiscal = AllFiscal.map((data) => (
        <option budget={data.amount} value={data.fiscal}>{data.fiscal}</option>
    ))

    useEffect(() => {
        setBudget(props.FiscalBudget.amount)
    },[])

    useMemo(() => {
        getAllStats()
    },[thisFiscalYear])

    return(
        <div className={`custom-card animate__animated animate__fadeIn`}>
            <div className="w-100 animate__animated animate__fadeIn" style={{height:'95%',overflowY:'auto',overflowX:'hidden'}}> 
                <div className='d-flex' style={{width:'100%',padding:10, borderBottom:'2px solid whitesmoke'}}>
                    <FilterCard
                        Name='Stats'
                        Active={adminmenu} 
                        onClick={() => {
                            setAdminMenu('Stats')
                        }}
                        
                    />

                    <FilterCard
                        Name='Users'
                        Active={adminmenu} 
                        onClick={() => {
                            setAdminMenu('Users')
                        }}
                        
                    />

                    {adminmenu == 'Stats' &&
                        <div className='d-flex animate__animated animate__fadeIn' style={{width:'20%',justifyContent:'center',alignItems:'center'}}>
                            <p className='mb-0' style={{marginRight:'10%'}}>Fiscal Year</p>

                            <select 
                                className="form-select w-50" 
                                defaultValue={thisFiscalYear}
                                onChange={(e) => {
                                    setLoaded(false)
                                    setThisFiscalYear(e.target.value)

                                    const selectedIndex = e.target.selectedIndex;
                                    const selectedOption = e.target[selectedIndex];
                                    const budgetVal = selectedOption.getAttribute('budget')

                                    console.log('fy value ==> ',budgetVal)
                                    setBudget(budgetVal)
                                }}
                            >
                                {renderAllFiscal}
                            </select>
                        </div>
                    }
                    
                </div>
                {adminmenu == 'Stats' &&
                    <>
                        {loaded?
                            (
                                <>
                                    <div className="row">
        
                                        <div className="col-xl-2 col-6 " style={{margin:'auto'}}>

                                            <StatCardRound
                                                Value = {budget? formatMoney(budget) : 'N / A'}
                                                Title = 'Total Budget' 
                                                BgColor = 'rgb(25, 118, 210)'
                                            />
                                        </div>

            

                                        <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                            <StatCardRound
                                                Value = {'$' + formatMoney(statsRequest?.spent)}
                                                Title = 'Estimated Approved' 
                                                BgColor = 'rgb(25, 118, 210)'
                                            />
                                        </div>


                                        <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                            <StatCard
                                                Value = {statsRequest?.total}
                                                Title = 'Total Requests' 
                                                BgColor = '#1976D2'
                                            />
                                        </div>
                                    
                                        <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                            <StatCard
                                                Value = {statsRequest?.approved}
                                                Title = 'Approved Requests' 
                                                BgColor = '#388E3C'
                                            />
                                        </div>

                                        <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                            <StatCard
                                                Value = {statsRequest?.pending}
                                                Title = 'Pending Requests' 
                                                BgColor = '#F57C00'
                                            />
                                        </div>

                                        <div className="col-xl-2 col-6" style={{margin:'auto'}}>
                                            <StatCard
                                                Value = {statsRequest?.rejected}
                                                Title = 'Rejected Requests' 
                                                BgColor = '#D32F2F'
                                            />
                                        </div>
                                    
                                    </div>

    

                                    <div className="row animate__animated animate__fadeIn">
                                        <div className="col-xl-5 col-12" style={{margin:'auto'}}>
                                            <Doughnut 

                                                data={budgetPerDepartment}
                                                width={'50%'}
                                                height={'25%'}
                                                options={{ 
                                                    maintainAspectRatio: true ,
                                                    plugins: {
                                                        legend: {
                                                            display: true,
                                                            position: 'left'
                                                        }
                                                    }
                                                }}
                                            
                                            />
                                        </div>

                                        <div className="col-xl-7 col-12" style={{margin:'auto'}}>
                                            <Chart
                                                type='bar'
                                                data={budgetPerMonth}
                                                height={'100%'}
                                                options={{ maintainAspectRatio: true }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )

                            :

                            (
                                <div className="w-100" style={{height:'95%',overflowY:'hidden',overflowX:'hidden',marginTop:'1%'}}>

                                    <div className="row">
                    
                                        <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                            <div className="d-flex statCardRound skeleton-box">
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
                    
                                        <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                            <div className="d-flex statCardRound skeleton-box">
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
                    
                    
                                        <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                            <div className="statCard skeleton-box">
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
                    
                                    
                                        <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                            <div className="statCard skeleton-box">
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
            
                                        <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                            <div className="statCard skeleton-box">
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
                    
                                    
                                        <div className="col-xl-2 col-6 " style={{margin:'auto'}}>
                                            <div className="statCard skeleton-box">
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
                                        
                                    </div>
                    
                                    <br/> <br/><br/> <br/>
                    
                                    <div className="row">
                                    
                    
                                        <div className="col-xl-5 col-12" style={{margin:'auto',justifyContent:'center',alignItems:'center'}}>
                                            <div className=" skeleton-box" style={{width:'100%',height:400,borderRadius:24}}>
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
                    
                                        <div className="col-xl-7 col-12" style={{margin:'auto'}}>
                                            <div className=" skeleton-box" style={{width:'100%',borderRadius:24,height:400}}>
                                                <div class="skeleton-animation"></div>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            )
                        }
                       
                    </>
                }
                

                {adminmenu == 'Users' &&
                    <div className="row">

                        <div className="col-12" style={{margin:'auto'}}>
    
                            <div className="user-card">
                                <h6 className="mb-3">Registered Users</h6>
                                <div className="card-inside">
                                    <div className="row" >
                                        <div className="col-3 text-center"><b>Name</b></div>
                                        <div className="col-3 text-center"><b>Email</b></div>
                                        <div className="col-3 text-center"><b>Joined</b></div>
                                        <div className="col-3 text-center"><b>Status</b></div>
                                    </div>
                                </div>
    
                                {renderUsers}
                            </div>
                        </div>
    
                    </div>
                }
        
            </div>           
        </div>
    )
   
}

export default Home